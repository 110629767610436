<template>
    <div class="postavke">
        Test postavke
    </div>
</template>
<script>
  export default {
    name: 'postavke',
    components: {
    }
  }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
</style>
